<template>
    <v-container fluid class="wave">
        <v-row style="background-color: white;" justify="start" align="start">
            <v-col cols="12">
                <span class="custom-orange-text mx-3" style="font-size: 18px;">#aofloisvos</span>
                <!-- <v-icon class="custom-orange-text" style="padding-bottom: 10px;" @click="openSocial('facebook')"> mdi-facebook</v-icon>
                <v-icon class="custom-orange-text mx-5" style="padding-bottom: 10px;" @click="openSocial('instagram')"> mdi-instagram</v-icon>
                <v-icon class="custom-orange-text mr-5" style="padding-bottom: 10px;" @click="openSocial('youtube')"> mdi-youtube </v-icon> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
            openSocial(social) {
                if (social === 'facebook') {
                    window.open('https://www.facebook.com/backinthegamegr', '_blank')
                } else if (social === 'instagram') {
                    window.open('https://www.instagram.com/backinthegame_2012/', '_blank')
                } else if (social === 'youtube') {
                    window.open('https://www.youtube.com/@BackIntheGameBasketball', '_blank')
                } else if (social === 'tiktok') {
                    window.open('https://www.tiktok.com/@backinthegame2012', '_blank')
                }
            }
        }
    }
</script>

<!-- <style scoped>
</style> -->
