import Vue from 'vue'
// import { WebHistory, Router } from 'vue-router'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/News'
    },
    {
        path: '/Schedule',
        component: () => import('@/routes/Schedule'),
        name: 'Schedule',
        meta: 'Πρόγραμμα'
    },
    {
        path: '/Standings',
        alias: '/',
        component: () => import('@/routes/Standings'),
        name: 'Standings',
        meta: 'Βαθμολογία'
    },
    {
        path: '/Teams',
        component: () => import('@/routes/Teams'),
        name: 'Teams',
        meta: 'Ομάδες'
    },
    {
        path: '/Team/:id',
        component: () => import('@/routes/Team'),
        name: 'Team',
        meta: 'Ομάδα'
    },
    {
        path: '/Player/:id',
        component: () => import('@/routes/Player'),
        name: 'Player',
        meta: 'Παίκτης'
    },
    {
        path: '/Match/:id',
        component: () => import('@/routes/Match'),
        name: 'Match',
        meta: 'Παιχνίδι'
    },
    {
        path: '/Facebook/Post/Match/:id',
        component: () => import('@/routes/FacebookPostMatch'),
        name: 'FacebookPostMatch',
        meta: 'Παιχνίδι'
    },
    {
        path: '/Courts',
        component: () => import('@/routes/Courts'),
        name: 'Courts',
        meta: 'Γήπεδα'
    },
    {
        path: '/News',
        component: () => import('@/routes/News'),
        name: 'News',
        meta: 'Νέα'
    },
    {
        path: '/Article/:id',
        component: () => import('@/routes/Article'),
        name: 'Article',
        meta: 'Άρθρο'
    },
    {
        path: '/Profile',
        component: () => import('@/routes/Profile'),
        name: 'Profile',
        meta: 'Προφίλ'
    },
    {
        path: '/Register',
        component: () => import('@/routes/Register'),
        name: 'Register',
        meta: 'Εγγραφή'
    },
    {
        path: '/Philosophy',
        component: () => import('@/routes/AboutUs'),
        name: 'Philosophy',
        meta: 'Φιλοσοφία'
    },
    {
        path: '/Regulations',
        component: () => import('@/routes/Regulations'),
        name: 'Regulations',
        meta: 'Κανονισμός'
    },
    {
        path: '/ContactUs',
        component: () => import('@/routes/ContactUs'),
        name: 'ContactUs',
        meta: 'Επικοινωνία'
    },
    {
        path: '/Stats',
        component: () => import('@/routes/Stats'),
        name: 'Stats',
        meta: 'Στατιστικά'
    }
]

const scrollBehavior = (to, from, savedPosition) => {
    const scrollpos = { left: 0, top: 0 }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(scrollpos)
        }, 600) // transition just before 600ms
    })
}

const router = new Router({
    // history: new WebHistory(),
    scrollBehavior,
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    return next()
})

export default router
